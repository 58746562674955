import React from 'react'
import { graphql } from 'gatsby'
import rehypeReact from "rehype-react"
import Layout from '../components/Layout'
import { connect } from 'react-redux'
import 'katex/dist/katex.min.css'
import {
  onSidebarContentSelected,
  onSetSidebarContentEntry,
  onSetAnchorHide,
  onSetSidebarHide,
} from '../actions/layout'
import { getSidebarSelectedKey, getSidebarEntry } from '../store/selectors'

import ExercisesInThisSection from "../partials/ExercisesInThisSection/ExercisesInThisSection"
import ProgrammingExercise from "../partials/ProgrammingExerciseHelpers/ProgrammingExercise"
import SampleOutput from "../partials/SampleOutput"
import TextBox from "../partials/TextBox"

function Template({
  data, // this prop will be injected by the GraphQL query below.
  onSidebarContentSelected,
  selectedKey,
  onSetSidebarContentEntry,
  sidebarEntry,
  onSetAnchorHide,
  onSetSidebarHide,
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, htmlAst, id } = markdownRemark
  
  const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: { 
        "exercises-in-this-section": ExercisesInThisSection,
        "programming-exercise": ProgrammingExercise,
        "sample-output": SampleOutput,
        "text-box": TextBox
      },
    }).Compiler

  const hideAnchor =
    frontmatter.hideAnchor === null ? false : frontmatter.hideAnchor
  const hideSidebar = frontmatter.sidebar === null ? true : false

  onSetAnchorHide(hideAnchor)
  onSetSidebarHide(hideSidebar)

  if (selectedKey !== id) onSidebarContentSelected(id)
  if (sidebarEntry !== frontmatter.sidebar)
    onSetSidebarContentEntry(frontmatter.sidebar)

  return (
    <Layout onPostPage={true}>
      <div className="blog-post-container">
        <div className="blog-post">
          {frontmatter.showTitle && <h1 align="center">{frontmatter.title}</h1>}
          {renderAst(htmlAst)}
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    selectedKey: getSidebarSelectedKey(state),
    sidebarEntry: getSidebarEntry(state),
  }
}

const mapDispatchToProps = {
  onSidebarContentSelected,
  onSetSidebarContentEntry,
  onSetAnchorHide,
  onSetSidebarHide,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Template)

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      fields {
        slug
      }
      id
      htmlAst
      frontmatter {
        title
        sidebar
        showTitle
        path
      }
    }
  }
`
