import React from "react"

import ExerciseDescription from "./ExerciseDescription"
import ProgrammingExerciseCard from "./ProgrammingExerciseCard"

class ProgrammingExercise extends React.Component {

  render() {
    const { children, name } = this.props

    return (
      <ProgrammingExerciseCard name={name} >
        <ExerciseDescription>{children}</ExerciseDescription>
      </ProgrammingExerciseCard>
    )
  }
}

export default ProgrammingExercise
