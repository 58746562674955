import React from "react"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown as icon } from "@fortawesome/free-solid-svg-icons"
import withSimpleErrorBoundary from "../../util/withSimpleErrorBoundary"
import ExerciseList from "./ExerciseList"

class ExercisesInThisSection extends React.Component {
  state = {
    render: false,
  }

  componentDidMount() {
    this.setState({ render: true })
  }

  render() {
    if (!this.state.render) {
      return <div>Loading...</div>
    }
    return (
      <div>
      <h3>
      {"Exercises in this section:"}
      </h3>
      <div>
      <ExerciseList />
      </div>
      </div>
    )
  }
}

export default withSimpleErrorBoundary(ExercisesInThisSection)
